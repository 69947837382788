<template>
  <v-card class="card text-center">
    <h3 class="card-text mb-8">
      {{ $t("create_campaign_page.show_address_step_title") }}
    </h3>
    <v-row>
      <v-col class="text-left" cols="12" md="5">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-autocomplete
            v-model="select"
            :items="items"
            item-text="name"
            item-value="criteriaID"
            :search-input.sync="countryValue"
            class="mx-4 font-family-raleway-medium input-border"
            :rules="nameRules"
            :label="$t('create_campaign_page.select_address_input')"
            @input="handleSelect"
            height="60"
            outlined
            flat
          ></v-autocomplete>
        </v-form>
      </v-col>
      <v-col cols="12" md="7">
        <div style="height: 350px; width: 100%; overflow: hidden">
          <l-map :center="coordsOfCenter" :zoom="zoom" style="height: 365px; z-index: 0">
            <l-tile-layer :url="url"></l-tile-layer>
            <l-marker :lat-lng="coordsOfMarker">
              <l-icon :icon-size="iconSize" :icon-url="icon"></l-icon>
            </l-marker>
          </l-map>
        </div>
      </v-col>
    </v-row>
    <v-row class="align-center">
      <v-col class="mt-5 font-family-raleway-medium" cols="6">
        <v-btn
          class="back-btn text-decoration-underline"
          @click="handleClickBack"
        >
          {{ $t("back_button") }}
        </v-btn>
      </v-col>
      <v-col class="mt-5 font-family-raleway-medium text-right" cols="6">
        <v-btn
          class="button font-weight-bold next-btn font-family-raleway-medium"
          @click="handleClick"
          width="240px"
          height="60"
        >
          {{ $t("create_campaign_page.next_button") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { LIcon, LMap, LMarker, LTileLayer } from "vue2-leaflet";
import locationIcon from "../../assets/icon/location-sign-svgrepo-com.svg";
import { getCities, getCoordsOfPlace } from "@/services/autocomplete";

export default {
  name: "ShowYourAddress",
  components: { LMap, LTileLayer, LMarker, LIcon },
  data() {
    return {
      getCoordsLoading: false,
      select: null,
      items: [],
      radioVal: false,
      zoom: 13,
      coordsOfCenter: { lat: 23.000989, lng: 53.324344 },
      coordsOfMarker: { lat: 23.000989, lng: 53.324344 },
      url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      icon: locationIcon,
      locationId: 0,
      iconSize: [30, 30],
      countryValue: "",
      campaign: {},
      coords: {},
      nameRules: [(v) => !!v || this.$t("required_text")],
      valid: false,
    };
  },
  created() {
    const campaign = localStorage.getItem("campaign");
    const parseToObj = JSON.parse(campaign);
    this.campaign = parseToObj;
    if (parseToObj.locationId) {
      const { country } = parseToObj.campaignTargets;
      this.handleSelect(parseToObj.locationId);
      getCities(country, false)
        .then((res) => {
          this.items = res;
        })
        .catch((err) => console.log(err));
      this.select = parseToObj.locationId;
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        this.coordsOfCenter = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.coordsOfMarker = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.coords.latitude = position.coords.latitude.toString();
        this.coords.longitude = position.coords.longitude.toString();
      });
      getCities("a", false)
        .then((res) => {
          this.items = res;
        })
        .catch((err) => console.log(err));
    }
  },
  methods: {
    async handleSelect(val) {
      this.locationId = val;
      const res = await getCoordsOfPlace(val);
      this.coords.latitude = res.latitude.toString();
      this.coords.longitude = res.longitude.toString();
      this.coordsOfCenter = { lat: res.latitude, lng: res.longitude };
      this.coordsOfMarker = { lat: res.latitude, lng: res.longitude };
    },
    handleClickBack() {
      this.$router.push("suggest-keyword");
    },
    async handleClick() {
      await this.$refs.form.validate();
      if (this.valid) {
        localStorage.setItem(
          "campaign",
          JSON.stringify({
            ...this.campaign,
            locationId: this.locationId,
            campaignTargets: {
              country: this.countryValue,
              adLocation: this.coords,
            },
          })
        );
        await this.$router.replace("approve-edit");
      }
    },
  },
  watch: {
    countryValue(val) {
      if (val) {
        getCities(val, false)
          .then((res) => {
            this.items = res;
          })
          .catch((err) => console.log(err));
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/style/style.scss";

.map {
  width: 100%;
}

.text {
  font-size: 16px;
  font-weight: 500;
}
</style>
