import { coreApi } from "@/services/index";

// const APIKEY = "AIzaSyDBgJCoVbb0yKjbpvxaq21xfhRLGMbOsp4";

export const getCities = async (val, countries = false) => {
  const response = await coreApi.get(
    `/collections/searchCountry?searchValue=${val}&onlyCountries=${countries}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data || [];
};

export const getCoordsOfPlace = async (val) => {
  const response = await coreApi.get(
    `/collections/getGeolocation?criterionId=${val}`
  );
  return response.data || {};
};
